
.navbar {
    border-radius: 10px;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
}

.card {
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    border-radius: 8px;
    background-color: white;
    text-align: center;
}

.pill {
    font-size: 10px; 
     padding: 3px 5px; 
     border-radius: 5px;
     background-color: #e2e2e2; 
     color: rgb(31, 31, 31)
}

.dataBlocks{
    height: 60px;

    color: #8B8B8B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;
    margin-right: 5px;

    background-color: #f3f3f3;
    border-color: #f3f3f3;
    border-radius: 8px;
    margin-top: 10px;
}

.dataBlocks:hover{
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    border-radius: 8px;
}

.data{
    font-size: 15px;
     color: #575757;
      margin-top: 6px;
}




