#pageLoading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: #F5F5F5;
  }
  
  .threeBalls {
    margin: 0 auto;
    width: 70px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
  }
  
  .threeBalls .ball {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: bouncedelay 2.0s infinite cubic-bezier(.62, .28, .23, .99) both;
    animation: bouncedelay 2.0s infinite cubic-bezier(.62, .28, .23, .99) both;
  }
  
  .threeBalls .ball1 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
  }
  
  .threeBalls .ball2 {
    -webkit-animation-delay: -.08s;
    animation-delay: -.08s;
  }
  
  @keyframes bouncedelay {
    0% {
      bottom: 0;
      background-color: #03A9F4;
    }
    16.66% {
      bottom: 40px;
      background-color: #FB6542;
    }
    33.33% {
      bottom: 0px;
      background-color: #FB6542;
    }
    50% {
      bottom: 40px;
      background-color: #FFBB00;
    }
    66.66% {
      bottom: 0px;
      background-color: #FFBB00;
    }
    83.33% {
      bottom: 40px;
      background-color: #03A9F4;
    }
    100% {
      bottom: 0;
      background-color: #03A9F4;
    }
  }
  
  @-webkit-keyframes bouncedelay {
    0% {
      bottom: 0;
      background-color: #03A9F4;
    }
    16.66% {
      bottom: 40px;
      background-color: #FB6542;
    }
    33.33% {
      bottom: 0px;
      background-color: #FB6542;
    }
    50% {
      bottom: 40px;
      background-color: #FFBB00;
    }
    66.66% {
      bottom: 0px;
      background-color: #FFBB00;
    }
    83.33% {
      bottom: 40px;
      background-color: #03A9F4;
    }
    100% {
      bottom: 0;
      background-color: #03A9F4;
    }
  }